::-webkit-calendar-picker-indicator {
  padding: revert;
  margin-left: 0px;
}

/* .react-loading-skeleton {
  vertical-align: top;
} */

dt {
  margin-bottom: .5rem;
}

dt::after {
  content: ':';
}

@media (min-width: 576px) { 
  dt {
    position: relative
  }
  dt[class*="col-sm"]::after {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 768px) { 
  dt[class*="col-md"]::after {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 992px) { 
  dt[class*="col-lg"]::after {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1200px) { 
  dt[class*="col-xl"]::after  {
    position: absolute;
    top: 0;
    right: 0;
  }
}

dt {
  position: relative;
  margin-bottom: .5rem;
}

dt::after {
  content: ':';
  position: absolute;
  top: 0;
  right: 0;
}

.input-skeleton {
  font-size: 1.5em
}

.rbt-input-main, .rbt-input-hint {
  text-transform: inherit;
}

input[type="date"]:read-only::-webkit-inner-spin-button,
input[type="date"]:read-only::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.overflow-visible {
  overflow: visible !important;
}

.accordion > .card .card-header  {
  border-radius: 0;
  margin-bottom: -1px;
}

.card > .overflow-hidden {
  border-radius: inherit;
}

.position-unset {
  position: unset !important;
}

.form-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  vertical-align: top;
}

.list-details .list {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 11rem;
  top: 0;
  bottom: 0;
  overflow: auto;
  /* white-space: nowrap */
}

/* .rbt-menu {
  width: initial !important;
} */

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.pulse {
  overflow: visible;
  position: relative;
  z-index: 1;
}

.pulse::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: pulse 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  border-radius: inherit;
  z-index: -1;
  background-color: inherit;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* td .btn-icon {
  padding: .05rem .5rem;
} */

.list-group.is-invalid,
.list-group.is-invalid .list-group-item {
  border-color: #dc3545;
}

.was-validated .list-group.is-valid,
.was-validated .list-group.is-valid .list-group-item {
  border-color: #28a745;
}